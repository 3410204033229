import { SearchIcon } from '../icons'
import clsx from 'clsx'

const SearchInput = ({
	name,
	value,
	setValue,
	placeholder,
	isInactive,
	showShadow = true,
	additionalClasses = '',
	containerWidth = 'w-80',
}: {
	name: string
	value: string
	setValue: (str: string) => void
	placeholder: string
	isInactive: boolean
	showShadow?: boolean
	additionalClasses?: string
	containerWidth?: string
}) => {
	return (
		<div className={`${containerWidth} group`}>
			<div
				className={clsx(
					'relative rounded-md dark:drop-shadow-none',
					showShadow && 'drop-shadow-menu',
				)}
			>
				<div
					className={clsx(
						isInactive && 'text-textInactiveLight dark:text-textInactiveDark',
						!isInactive &&
							'text-textSecondaryDark group-hover:text-textSecondaryLight dark:text-textSecondaryDark',
						!isInactive &&
							value !== '' &&
							'text-textPrimaryDark dark:text-textPrimaryLight',
						'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3',
						!isInactive &&
							value === '' &&
							'text-textSecondaryLight dark:text-textSecondaryDark',
						additionalClasses,
					)}
				>
					<SearchIcon />
				</div>
				<input
					type="text"
					value={value}
					onChange={e => {
						if (e.target.value && value !== e.target.value) {
							setValue(e.target.value)
						} else if (!e.target.value) {
							setValue('')
						}
					}}
					name={name}
					id={name}
					disabled={isInactive}
					className={clsx(
						'cursor-pointer py-3 font-abc text-paragraphMedium',
						isInactive &&
							'cursor-not-allowed bg-uiPrimaryLight text-textInactiveLight placeholder:text-textInactiveLight dark:bg-uiSecondaryDark dark:text-textInactiveDark dark:placeholder:text-textInactiveDark',
						!isInactive &&
							'focus:bg-uiPrimaryDark focus:text-textPrimaryDark focus:dark:bg-uiPrimaryLight',
						'block w-full rounded-md border-0 pl-10 outline-0 ring-0 focus:outline-0 focus:ring-0',
						!isInactive &&
							'placeholder:text-textSecondaryDark group-hover:placeholder:text-textSecondaryLight dark:placeholder:text-textSecondaryDark ',
						!isInactive &&
							value !== '' &&
							'bg-uiPrimaryDark text-textPrimaryDark dark:bg-uiPrimaryLight dark:text-textPrimaryLight',
						!isInactive &&
							value === '' &&
							'bg-uiPrimaryLight dark:bg-uiSecondaryDark',
					)}
					placeholder={placeholder}
				/>
			</div>
		</div>
	)
}

export default SearchInput
